import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/common/Layout/layout"
import SEO from "../../components/common/SEO/seo"
import BannerPage from "../../components/banner/BannerPage/bannerPage"
import SectionTextImage from "../../components/section/TextImage/textImage"
import CallToAction from "../../components/section/CallToAction/callToAction"

const ContentMarketingPage = () => {
    const data = useStaticQuery(graphql`
    query {
      contentMarketingImage1: file(relativePath: { eq: "images/sections/marketing-digital/content-marketing/que-es-estrategia-content-marketing-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      contentMarketingImage2: file(relativePath: { eq: "images/sections/marketing-digital/content-marketing/ventajas-content-marketing-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      contentMarketingImage3: file(relativePath: { eq: "images/sections/marketing-digital/content-marketing/que-tipo-contenido-creamos-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return (
        <Layout>
            <SEO
                title="Marketing de Contenidos"
                description="Agencia de Marketing Digital en Barcelona experta en marketing  y estrategia de contenidos únicos y de calidad. Contenido en diferentes formatos y medios."
            />

            <BannerPage
                title="Agencia de Marketing de Contenidos"
                text="Content Marketing de alta calidad para todo tipo de empresas"
                color="green"
            />

            <SectionTextImage
                title1="Content Marketing "
                title2="¿Qué es?"
                text={<Fragment>
                    Entendemos como una <strong>estrategia de Content Marketing</strong> aquella que mediante el contenido consigue crear a su alrededor una comunidad, una red de contactos, una trama de embajadores fieles a una marca. <br /><br />
                    <strong>El objetivo de una estrategia de contenidos</strong> es posicionar la marca en los más alto de los buscadores y hacer que los usuarios consuman tu contenido y no el de la competencia.
                </Fragment>}
                image={data.contentMarketingImage1.childImageSharp.fluid}
                imageAlt="¿Qué es el Content Marketing? - Agencia de Marketing de Contenidos"
                color="green"
                containerTextRight={false}
            />

            <SectionTextImage
                title1="Ventajas del "
                title2="Content Marketing"
                text={<Fragment>
                    <ol class="text-left">
                        <li><strong>Favorece al posicionamiento SEO</strong>: Google premia el contenido actualizado de calidad.</li>
                        <li><strong>Mayor reputación online</strong>: Contribuye a generar más engagement de la marca, más confianza, más cercanía.</li>
                        <li><strong>Rentabilidad Alta</strong>: El contenido que se genera puede ser visto por todos los usuarios que accedan al espacio web.</li>
                        <li><strong>Gran alcance</strong>: tiene más posibilidad de viralización que un medio tradicional ya que Internet puede ser visto desde cualquier lugar y dispositivo.</li>
                    </ol>
                </Fragment>}
                image={data.contentMarketingImage2.childImageSharp.fluid}
                imageAlt="Ventajas del Content Marketing - Agencia de Marketing de Contenidos"
                color="green"
                containerTextRight={true}
            />

            <SectionTextImage
                title1="¿Qué tipo de "
                title2="contenido creamos?"
                text={<Fragment>
                    <ul class="text-left">
                        <li><strong>Contenido en diferentes formatos</strong>: Textual, Infografías, Gif’s, Vídeos, Motion graphics, etc.</li>
                        <li><strong>Contenido para diferentes canales de comunicación</strong>: redes sociales, webs, medios de comunicación, blogs, presentaciones, brochures, presentación de empresa, etc.</li>
                    </ul>
                    En IBX recomendamos que todas las empresas inviertan en <strong>marketing de contenidos</strong> ya que favorece y repercute directamente en su estrategia de marketing digital.
                </Fragment>} image={data.contentMarketingImage3.childImageSharp.fluid}
                imageAlt="¿Qué contenido creamos en IBX? - Agencia de Marketing de Contenidos"
                color="green"
                containerTextRight={false}
            />

            <CallToAction
                color="green"
                title="¿Quieres que te elaboremos una estrategia de Content Marketing?"
                text={<Fragment>
                    Somos tu <strong>agencia de marketing de contenidos en Barcelona!</strong><br />
                </Fragment>}
                button="¿Empezamos a redactar?"
                buttonUrl="/contacto"
            />

        </Layout>
    )

}
export default ContentMarketingPage
